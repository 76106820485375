import React from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import '../styles/HomePage.css';

const LoadingScreen = () => {
  return (
      <ProgressSpinner/>
  )
}

export default LoadingScreen;