import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import CodeForm from './CodeForm';
import '../styles/HomePage.css';
import {login, pushError, endLoading} from '../../actions/dataActions';
import {DATA} from '../../navigation/routes';
import LoadingScreen from '../loading/LoadingScreen';

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (code) => {
    const processed = code.toUpperCase().replace(/_/g, '')
    if (processed.length < 6) {
      this.props.pushError("Incomplete code")
      return
    }
    this.props.login(processed)
  }

  render() {
    if(this.props.tempComplete & this.props.pulseOxComplete) {
      this.props.endLoading()
      return <Redirect to={{pathname: DATA}} />
    }
    return (      
      <div className="Home-bg">
          <div className="Home-login">
            <h1>CovidiKit Dashboard</h1>
          </div>
          { this.props.loading
          ? <LoadingScreen />
          : <CodeForm onSubmit={this.handleSubmit}></CodeForm>}
      </div>
    );  
  }
}

const mapStateToProps = state => ({
    tempComplete: state.data.fetchStatusTemp,
    pulseOxComplete: state.data.fetchStatusPulseOx,
    loading: state.data.loading,
})

const mapDispatchToProps = dispatch => ({
    login: id => dispatch(login(id)),
    pushError: error => dispatch(pushError(error)),
    endLoading: () => dispatch(endLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
