import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as ROUTES from './navigation/routes';
import HomePage from './components/home/HomePage';
import GraphPage from './components/graph/GraphPage';

import './components/styles/HomePage.css';
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

function App(props) {
  return (
    <Provider store={props.store}>
      <PersistGate loading={null} persistor={props.persistor}>
        <Router>
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.DATA} component={GraphPage} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
