import { 
    createStore, 
    applyMiddleware, 
    compose,
    combineReducers
} from 'redux'
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk'
import data from './dataReducer'

export default function configureStore() {
    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['auth', 'data']
    }
    let allReducers = combineReducers({
        data,
    })
    const persistedReducer = persistReducer(persistConfig, allReducers)
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(thunk)),
    )
    const persistor = persistStore(store)

    return { store, persistor }
}


