import axios from 'axios';

export const INITIALIZE_FIRESTORE = 'INITIALIZE_FIRESTORE'
export const FETCH_REQUEST_PULSEOX = 'FETCH_REQUEST_PULSEOX'
export const FETCH_SUCCESS_PULSEOX = 'FETCH_SUCCESS_PULSEOX'
export const FETCH_REQUEST_TEMP = 'FETCH_REQUEST_TEMP'
export const FETCH_SUCCESS_TEMP = 'FETCH_SUCCESS_TEMP'
export const FETCH_REQUEST_LATEST = 'FETCH_REQUEST_LATEST'
export const FETCH_SUCCESS_LATEST = 'FETCH_SUCCESS_LATEST'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const UPDATE_PATIENT_CODE = 'UPDATE_PATIENT_CODE'
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'
export const SIGN_OUT = 'SIGN_OUT'

export function startLoading() {
    return {
        type: START_LOADING,
    }
}

export function endLoading() {
    return {
        type: END_LOADING,
    }
}

function checkErrors(data) {
    if (data['error_code'] === 104) {
        return null
    } else if (data['error_code'] !== null) {
        throw data.error
    }
    return data
}

export function updatePatientCode(code) {
    return {
        type: UPDATE_PATIENT_CODE,
        code: code,
    }
}

export function login(id) {
    return function(dispatch) {
        dispatch(startLoading())
        dispatch(updatePatientCode(id))
        dispatch(fetchPulseOxData(id))
        dispatch(fetchTemperatureData(id))
    }
}

export function fetchPulseOxData(id) {
    return function(dispatch) {
        dispatch({type: FETCH_REQUEST_PULSEOX})
        axios.get(`https://us-central1-patchd-covid-response-demo.cloudfunctions.net/httpFetchPulseOxi?user_code=${id}`)
        .then(
            res => {
                dispatch(fetchSuccessPulseOx(checkErrors(res.data)))
            },
            error => {
                dispatch(endLoading())
                dispatch(fetchFailure(error))
            }
        ).catch(error => {
            dispatch(endLoading())
            dispatch(fetchFailure(error))
        })
    }
}

export function fetchTemperatureData(id) {
    return function(dispatch) {
        dispatch({type: FETCH_REQUEST_TEMP})
        axios.get(`https://us-central1-patchd-covid-response-demo.cloudfunctions.net/httpFetchTemperature?user_code=${id}`)
        .then(
            res => {                
                dispatch(fetchSuccessTemp(checkErrors(res.data)))
            },
            error => {
                dispatch(endLoading())
                dispatch(fetchFailure(error))
            }
        ).catch(error => {
            dispatch(endLoading())
            dispatch(fetchFailure(error))
        })
    }
}

export function fetchSuccessTemp(data) {
    return {
        type: FETCH_SUCCESS_TEMP,
        data: data
    }
}

export function fetchSuccessPulseOx(data) {
    return {
        type: FETCH_SUCCESS_PULSEOX,
        data: data
    }
}

export function fetchFailure(error) {
    return {
        type: FETCH_FAILURE,
        error: error
    }
}

export function fetchLatestSuccess(data) {
    return {
        type: FETCH_SUCCESS_LATEST,
        data: data
    }
}

export function fetchLatest(id) {
    return function(dispatch) {
        dispatch(startLoading())
        dispatch({type: FETCH_REQUEST_LATEST})
        axios
        .get(`https://us-central1-patchd-covid-response-demo.cloudfunctions.net/fetchLatestTemperature?user_code=${id}`)
        .then(res_temp => {
            if (res_temp.data.error_code === 102) {
                fetchFailure(res_temp.data.error)
                throw res_temp.data.error
            }
            axios
                .get(`https://us-central1-patchd-covid-response-demo.cloudfunctions.net/fetchLatestSpo2?user_code=${id}`)
                .then(res_spo2 => {
                    if (res_temp.data.error_code === 102) {
                        throw res_temp.data.error
                    }
                    dispatch(fetchLatestSuccess({
                        latestTemp: res_temp.data, 
                        latestSpo2: {
                            ts: res_spo2.data.ts,
                            reading: res_spo2.data.spo2,
                        },
                        latestPulse: {
                            ts: res_spo2.data.ts,
                            reading: res_spo2.data.pulse,
                        }
                    }))
                    dispatch(endLoading())
                })
        }).catch(error => {
            dispatch(fetchFailure(error))
            dispatch(endLoading())
        })
    }
}

export function pushError(error) {
    return fetchFailure(error)
}

export function signOut() {
    return {
        type: SIGN_OUT
    }
}