import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from 'primereact/button';
import LineGraph from './LineGraph';
import '../styles/GraphSelector.css';

const TIME_DAY = 'Day';
const TIME_WEEK = 'Week';
const TIME_MONTH = 'Month';

const VITAL_TEMP = 'Temperature';
const VITAL_SPO2 = 'SPO2';
const VITAL_PULSE = 'Pulse Rate';

class GraphSelector extends Component {
  constructor (props) {
    super (props);
    this.state = {
      variable: VITAL_TEMP,
      timePeriod: TIME_WEEK,
    };
    this.dataSelector = this.dataSelector.bind (this);
    this.handleTemp = this.handleTemp.bind (this);
    this.handleSpo2 = this.handleSpo2.bind (this);
    this.handlePulse = this.handlePulse.bind (this);
    this.handleDayClick = this.handleDayClick.bind (this);
    this.handleWeekClick = this.handleWeekClick.bind (this);
    this.handleMonthClick = this.handleMonthClick.bind (this);
  }

  formatForCharting (rawData, variable, timePeriod) {
    let fill = false;
    // let backgroundColor = '#ffb938';
    // let borderColor = '#ffb938';
    let yellow = '#ffcc66';
    let labels = null;
    let data = rawData.map (d => ({y: d.reading, x: d.ts}));
    return {
      labels: labels,
      datasets: [
        {
          label: variable + ' - ' + timePeriod,
          data: data,
          pointBorderColor: yellow,
          pointBackgroundColor: yellow,
          borderColor: yellow,
          lineTension: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          pointRadius: 5,
        },
      ],
      fill: fill,
      backgroundColor: 'rgba(0, 0, 0, 1)',
      borderColor: '#ffffff',
    };
  }

  dataSelector () {
    // console.log(this.state.timePeriod + '&' + this.state.variable)
    let variable = this.state.variable;
    var data = null;
    switch (this.state.timePeriod) {
      case TIME_DAY:
        if (variable === VITAL_TEMP) {
          data = this.formatForCharting (
            this.props.data.tempDaily,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_SPO2) {
          data = this.formatForCharting (
            this.props.data.spo2Daily,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_PULSE) {
          data = this.formatForCharting (
            this.props.data.pulseDaily,
            variable,
            this.state.timePeriod
          );
        }
        // console.log("DATA", data)
        return (
          <LineGraph
            data={data}
            vital={variable}
            timePeriod={this.state.timePeriod}
          />
        );
      case TIME_WEEK:
        if (variable === VITAL_TEMP) {
          data = this.formatForCharting (
            this.props.data.tempWeekly,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_SPO2) {
          data = this.formatForCharting (
            this.props.data.spo2Weekly,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_PULSE) {
          data = this.formatForCharting (
            this.props.data.pulseWeekly,
            variable,
            this.state.timePeriod
          );
        }
        // console.log("DATA", data)
        return (
          <LineGraph
            data={data}
            vital={variable}
            timePeriod={this.state.timePeriod}
          />
        );
      case TIME_MONTH:
        if (variable === VITAL_TEMP) {
          data = this.formatForCharting (
            this.props.data.tempMonthly,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_SPO2) {
          data = this.formatForCharting (
            this.props.data.spo2Monthly,
            variable,
            this.state.timePeriod
          );
        } else if (variable === VITAL_PULSE) {
          data = this.formatForCharting (
            this.props.data.pulseMonthly,
            variable,
            this.state.timePeriod
          );
        }
        // console.log("DATA", data)
        return (
          <LineGraph
            data={data}
            vital={variable}
            timePeriod={this.state.timePeriod}
          />
        );
      default:
        return null;
    }
  }

  handleTemp = () => this.setState ({variable: VITAL_TEMP});
  handleSpo2 = () => this.setState ({variable: VITAL_SPO2});
  handlePulse = () => this.setState ({variable: VITAL_PULSE});
  handleDayClick = () => this.setState ({timePeriod: TIME_DAY});
  handleWeekClick = () => this.setState ({timePeriod: TIME_WEEK});
  handleMonthClick = () => this.setState ({timePeriod: TIME_MONTH});

  render () {
    return (
      <div className="graph-selector-container">
        <p className="title-vital-signs">Vital Signs</p>
        <div className="variable-selector p-grid">
          <div className="p-sm-4 p-md-4">
            <Button
              label="Temperature"
              className="p-button-secondary button-vital"
              onClick={this.handleTemp}
              disabled={this.state.variable === VITAL_TEMP ? 'disabled' : ''}
            />
          </div>
          <div className="p-sm-4 p-md-4">
            <Button
              label="SPO2"
              className="p-button-secondary button-vital"
              onClick={this.handleSpo2}
              disabled={this.state.variable === VITAL_SPO2 ? 'disabled' : ''}
            />
          </div>
          <div className="p-sm-4 p-md-4">
            <Button
              label="Pulse"
              className="p-button-secondary button-vital"
              onClick={this.handlePulse}
              disabled={this.state.variable === VITAL_PULSE ? 'disabled' : ''}
            />
          </div>
        </div>
        <div className="line-graph-container">
          {this.dataSelector ()}
        </div>
        <div className="variable-selector p-grid">
          <div className="p-sm-4 p-md-4">
            <Button
              label="Day"
              className="p-button-secondary button-time"
              onClick={this.handleDayClick}
              disabled={this.state.timePeriod === TIME_DAY ? 'disabled' : ''}
            />
          </div>
          <div className="p-sm-4 p-md-4">
            <Button
              label="Week"
              className="p-button-secondary button-time"
              onClick={this.handleWeekClick}
              disabled={this.state.timePeriod === TIME_WEEK ? 'disabled' : ''}
            />
          </div>
          <div className="p-sm-4 p-md-4">
            <Button
              label="Max"
              className="p-button-secondary button-time"
              onClick={this.handleMonthClick}
              disabled={this.state.timePeriod === TIME_MONTH ? 'disabled' : ''}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data.values,
});

export default connect (mapStateToProps, null) (GraphSelector);
