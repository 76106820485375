import React from 'react';
import {Line} from 'react-chartjs-2';
import moment from 'moment'
import '../styles/LineGraphComponent.css'

const LineGraph = (props) => {
    const suggestedBounds = (props) => {
        if (props.vital === 'Temperature') {
            return {
                suggestedMin: 94,
                suggestedMax: 102,
            }
        } else if (props.vital === 'SPO2') {
            return {
                suggestedMin: 90,
                suggestedMax: 100,
            }
        } else if (props.vital === 'Pulse Rate') {
            return {
                suggestedMin: 40,
                suggestedMax: 100,
            }
        }
    }

    const xAxesTicks = (props) => {
        if (props.timePeriod === 'Day') {
            return {
                min: moment().subtract(24, 'hours'),
                max: moment(),
            }
        } else if (props.timePeriod === 'Week') {
            return {
                min: moment().subtract(7, 'days'),
                max: moment(),
            }
        } else if (props.timePeriod === 'Month') {
            return {
                min: moment().subtract(1, 'month'),
                max: moment(),
            }
        }
    }

    const yAxisLabel = (props) => {
        if (props.vital === 'Temperature') {
            return 'Temperature (°F)'
        } else if (props.vital === 'SPO2') {
            return 'SPO2 (%)'
        } else if (props.vital === 'Pulse Rate') {
            return 'Pulse Rate (beats/min)'
        }
    }


    const options = {
        legend: {
            display: false,
            position: 'bottom',
        },
        tooltip: {
            enabled:true,
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontSize: 16,
                    fontColor: '#f7f7f7',
                    ...suggestedBounds(props),
                    color:'#908f8e',
                },
                scaleLabel: {
                    display: true,
                    labelString: yAxisLabel(props),
                    fontColor: '#f7f7f7',
                    fontSize: 16,
                },
                gridLines: {
                    color:'#908f8e',
                    tickMarkLength: 5,
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 16,
                    fontColor: '#f7f7f7',
                    ...xAxesTicks(props),
                    userCallback: (label, index, labels) => moment(label).format('HH:mm'),
                    time: {stepSize: 6},
                }, 
                gridLines: {
                    display: true,
                    drawBorder: false,
                    color:'#908f8e',
                    zeroLineColor:'#908f8e',
                    tickMarkLength: 5,
                },
                type: 'time',
                bounds: 'ticks',
            }]
        },
        layout: {
            padding: {
                left: 2,
                right: 14,
                top: 18,
                bottom: 0
            }
        },
        maintainAspectRatio: false,
        animation: {
            duration: 1000,
            ease: 'linear'
        },
        hover: {
            animationDuration: 0,
        },
        responsive:true,
        responsiveAnimationDuration: 0,
        bezierCurve: false,
    };

    return (
        <div className='graph-container'>
            <Line data={props.data} options={options} height={310} width={380} style={{'background-color':'#ffff'}}/>
        </div>
    )
}

export default LineGraph;