import React, {Component} from 'react';
import {connect} from 'react-redux';
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';
import '../styles/CodeForm.css';

class CodeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  onInputChange(e) {
    if (e.originalEvent.keyCode === 13) {
      this.props.onSubmit(this.state.value)
    }
    this.setState({value: e.value})
  }

  render() {
    return (
      <div className="container">
        <div>
          <h3>Enter Patient Code</h3>
        </div>
        <div>
          <h4 style={{color:'#ce96ff'}}>{
            this.props.error ? `ERROR: ${this.props.error}` : null
          }</h4>
        </div>
        <div className="code-input-container">
          <InputMask 
            mask="******" 
            value={this.state.value} 
            onChange={(e) => this.onInputChange(e)}
            className="code-input"
            style={{textTransform:'uppercase', fontSize:'24px'}}
            >
          </InputMask>
        </div>
        <Button 
          label="Submit" 
          className="p-button-raised p-button-warning submit-button"
          onClick={() => {
            console.log("SOME STATE: ", this.state.value)
            this.props.onSubmit(this.state.value)
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.data.fetchError,
})

export default connect(mapStateToProps, null)(CodeForm);
