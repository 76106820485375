import produce from 'immer';
import moment from 'moment'
import {
    INITIALIZE_FIRESTORE,
    FETCH_FAILURE,
    FETCH_REQUEST_TEMP,
    FETCH_REQUEST_PULSEOX,
    FETCH_SUCCESS_TEMP,
    FETCH_SUCCESS_PULSEOX,
    FETCH_SUCCESS_LATEST,
    FETCH_REQUEST_LATEST,
    START_LOADING,
    END_LOADING,
    SIGN_OUT,
    UPDATE_PATIENT_CODE,
} from '../actions/dataActions';

const FETCH_STATUS_REQUESTED = 'FETCH_STATUS_REQUESTED'
const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS'
const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE'

export const initialState = {
    db: null,
    loading: false,
    fetchStatus: null,
    fetchStatusTemp: false,
    fetchStatusPulseOx: false,
    fetchError: null,
    values: {
        tempDaily: [],
        spo2Daily: [],
        pulseDaily: [],
        tempWeekly: [],
        spo2Weekly: [],
        pulseWeekly: [],
        tempMonthly: [],
        spo2Monthly: [],
        pulseMonthly: [],
    },
    latestTemp: {reading: null, ts: {_seconds: null}},
    latestPulse: {reading: null, ts: {_seconds: null}},
    latestSpo2: {reading: null, ts: {_seconds: null}},
    patientCode: null,
}

function filterTemperature(data, timePeriod) {
    let dateCutoff = moment()
    if (timePeriod === 'day') {
        dateCutoff = moment().subtract(24, 'hours')
    } else if (timePeriod === 'week') {
        dateCutoff = moment().subtract(7, 'days')
    } else if (timePeriod === 'month') {
        dateCutoff = moment().subtract(1, 'month')
    }
    return data.filter(d => {
        if (d.isCelsius === true) {
        return (d.reading > 30) & (d.reading < 48) 
                & moment.unix(d.ts._seconds).isAfter(dateCutoff)
        } else {
        return (d.reading > 88) & (d.reading < 112) 
                & moment.unix(d.ts._seconds).isAfter(dateCutoff)
        }
    })
}

function filterPulseOx(data, timePeriod) {
    let dateCutoff = moment()
    if (timePeriod === 'day') {
        dateCutoff = moment().subtract(24, 'hours')
    } else if (timePeriod === 'week') {
        dateCutoff = moment().subtract(7, 'days')
    } else if (timePeriod === 'month') {
        dateCutoff = moment().subtract(1, 'month')
    }
    return data.filter(d => {
        return (((d.spo2 > 75) & (d.spo2 <= 100))
                | ((d.pulse > 30) & (d.pulse < 240)))
                & moment.unix(d.ts._seconds).isAfter(dateCutoff)
    })
}

function groupBy(data, variable, keyFn) {
    let bins =  data.reduce((acc, obj) => {
      let key = keyFn(obj.ts._seconds)

      if(!acc[key]) {
        acc[key] = []
      }
      if (['temperature', 'temp'].includes(variable) & (obj.isCelsius === true)) {
        acc[key].push(Number(obj['reading']) * 1.8 + 32)
      } else if (['temperature', 'temp'].includes(variable) & (obj.isCelsius === false)) {
        acc[key].push(Number(obj['reading']))
      } else {
        acc[key].push(Number(obj['reading']))
      }
      return acc
    }, {})
    
    let averaged = Object.keys(bins).map(k => {
      let list = bins[k]
      return {
        ts: moment(k),
        reading: (list.reduce((p,c) => p + c, 0) / list.length).toFixed(1)
      }
    })
    return averaged
}

function groupByDay(data, variable) {
    let keyFn = (ts) => moment.unix(ts).hours(0).minutes(0).seconds(0).milliseconds(0).toString()
    return groupBy(data, variable, keyFn)
}

function groupBy8Hr(data, variable) {
    let keyFn = (ts) => {
        let nearest = Math.floor(moment.unix(ts).hours() / 8) * 8
        return moment.unix(ts).hours(nearest).minutes(0).seconds(0).milliseconds(0).toString()
    }
    return groupBy(data, variable, keyFn)
}

function groupByHour(data, variable) {
    let keyFn = (ts) => moment.unix(ts).minutes(0).seconds(0).milliseconds(0).toString()
    return groupBy(data, variable, keyFn)   
}


export default (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case INITIALIZE_FIRESTORE:
                draft.db = action.firestore
                break;
            case START_LOADING: 
                draft.loading = true
                break;
            case END_LOADING:
                draft.loading = false
                break;
            case FETCH_REQUEST_PULSEOX:
                draft.fetchStatus = FETCH_STATUS_REQUESTED
                break;
            case FETCH_REQUEST_TEMP:
                draft.fetchStatus = FETCH_STATUS_REQUESTED
                break;
            case FETCH_SUCCESS_TEMP:
                draft.fetchStatus = FETCH_STATUS_SUCCESS                
                draft.fetchStatusTemp = true
                if (action.data) {
                    draft.values.tempDaily = groupByHour(filterTemperature(action.data.temperatureData, 'day'), 'temperature')
                    draft.values.tempWeekly = groupBy8Hr(filterTemperature(action.data.temperatureData, 'week'), 'temperature')
                    draft.values.tempMonthly = groupByDay(filterTemperature(action.data.temperatureData, 'month'), 'temperature')
                }
                break;
            case FETCH_SUCCESS_PULSEOX:
                draft.fetchStatus = FETCH_STATUS_SUCCESS
                draft.fetchStatusPulseOx = true
                if (action.data) {
                    let dayData = filterPulseOx(action.data.pulseOxiData, 'day')
                    let weekData = filterPulseOx(action.data.pulseOxiData, 'week')
                    let monthData = filterPulseOx(action.data.pulseOxiData, 'month')
                    
                    draft.values.spo2Daily = groupByHour(dayData.map(d => ({reading: d.spo2, ts: d.ts})), 'spo2')
                    draft.values.spo2Weekly = groupBy8Hr(weekData.map(d => ({reading: d.spo2, ts: d.ts})), 'spo2')
                    draft.values.spo2Monthly = groupByDay(monthData.map(d => ({reading: d.spo2, ts: d.ts})), 'spo2')
    
                    draft.values.pulseDaily = groupByHour(dayData.map(d => ({reading: d.pulse, ts: d.ts})), 'pulse')
                    draft.values.pulseWeekly = groupBy8Hr(weekData.map(d => ({reading: d.pulse, ts: d.ts})), 'pulse')
                    draft.values.pulseMonthly = groupByDay(monthData.map(d => ({reading: d.pulse, ts: d.ts})), 'pulse')
                }
                break;
            case FETCH_FAILURE:
                draft.fetchStatus = FETCH_STATUS_FAILURE
                draft.fetchError = action.error
                break;
            case FETCH_SUCCESS_LATEST:
                draft.latestTemp = action.data.latestTemp
                draft.latestSpo2 = action.data.latestSpo2
                draft.latestPulse = action.data.latestPulse
                break;
            case FETCH_REQUEST_LATEST:
                draft.fetchStatus = FETCH_STATUS_REQUESTED
                break;
            case SIGN_OUT:
                draft.fetchStatusTemp = false
                draft.fetchStatusPulseOx = false
                break;
            case UPDATE_PATIENT_CODE:
                draft.patientCode = action.code
                break;
            default:
                break;
        }
    })